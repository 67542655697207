import React from 'react';

import Placeholder from '../Placeholder';
import SubpageContent from './content';
import SubpageContact from './contact';

import loadComponents from '../Loadable';

const SubpageAdditional = loadComponents('subpage-additional');

export default function Subpage(props) {
  const { content, additional, form, link, loaded } = props;
  return (
    <div style={{ width: `100%` }}>
      {!loaded ? (
        <IntroPlaceHolder />
      ) : (
        content && (
          <SubpageContent
            content={content}
            style={{ display: !loaded && `none` }}
          />
        )
      )}
      {loaded && (
        <>
          {additional && <SubpageAdditional data={additional} />}
          {form && <SubpageContact form={form} link={link} />}
        </>
      )}
    </div>
  );
}

const IntroPlaceHolder = () => {
  const sharedProps = {
    height: `24px`,
    colour: `#000`,
  };
  const lineMargin = `0.625rem`;
  return (
    <div>
      <Placeholder
        width="100%"
        {...sharedProps}
        style={{ marginBottom: lineMargin }}
      />
      <Placeholder
        width="100%"
        {...sharedProps}
        style={{ marginBottom: lineMargin }}
      />
      <Placeholder
        width="60%"
        {...sharedProps}
        style={{ marginBottom: `1.875rem` }}
      />
      <Placeholder
        width="100%"
        {...sharedProps}
        style={{ marginBottom: lineMargin }}
      />
      <Placeholder
        width="95%"
        {...sharedProps}
        style={{ marginBottom: lineMargin }}
      />
      <Placeholder
        width="100%"
        {...sharedProps}
        style={{ marginBottom: lineMargin }}
      />
      <Placeholder
        width="50%"
        {...sharedProps}
        style={{ marginBottom: `3.75rem` }}
      />
    </div>
  );
};
