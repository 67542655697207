import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';
import Form from '../Form';

import { TitlePlaceholder } from '../Form/placeholders';
import { AddressPlaceholder } from '../Address/placeholder';

import './contact.css';

import loadComponents from '../Loadable';

const H4 = loadComponents('h4');
const Address = loadComponents('address');

export default function SubpageContact({ form, link }) {
  return (
    <section className="subpageComponent">
      <Row justify="space-between">
        <Col w={{ width: `40%` }} xl={{ width: `45%` }}>
          <H4 size="36px" fallback={<TitlePlaceholder />}>
            Find <span>Us</span>
          </H4>
          <Address map fallback={<AddressPlaceholder />} />
        </Col>
        <Col
          className="contactForm"
          xxl={{ width: `50%` }}
          xl={{ width: `55%` }}
        >
          <Form fields={form} name={link} />
        </Col>
      </Row>
    </section>
  );
}
