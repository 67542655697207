import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import Container from '../components/Container';
import PageTitle from '../components/Subpage/title';
import Row from '../components/Container/row';
import Col from '../components/Container/column';
import Subpage from '../components/Subpage';

import '../components/Subpage/style.css';

export default class PageTemplate extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.initPage();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initPage = () => {
    if (!this._isMounted) {
      this._isMounted = true;
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 500);
    }
  };

  render() {
    const { loaded } = this.state;
    const { location, data } = this.props;
    const prevPage = location.state && location.state.prevPage;

    const { wpPage } = data;

    const pageTitle =
      wpPage.seo && wpPage.seo.title ? wpPage.seo.title : wpPage.title;

    const headerImage =
      wpPage?.header?.headerImage !== null &&
      wpPage?.header?.headerImage?.localFile?.childImageSharp?.gatsbyImageData;

    return (
      <Layout
        title={pageTitle}
        seo={wpPage.seo}
        location={wpPage.link}
        prevPage={prevPage}
      >
        <article>
          <PageTitle title={wpPage.title} image={headerImage} />
          <Container>
            <Row>
              <Col
                xl={{
                  width: `100%`,
                }}
                className="pageContent"
              >
                <Subpage
                  loaded={loaded}
                  content={wpPage.content}
                  additional={wpPage.layoutOptions?.layoutOptions}
                  form={wpPage.formBuilder?.formGroup}
                  link={wpPage.link}
                />
              </Col>
            </Row>
          </Container>
        </article>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      link
      ...PageSeo
      ...FormBuilder
      ...PageComponents
      ...SubpageHeader
    }
    wp {
      ...DefaultPageLayout
    }
  }
`;
